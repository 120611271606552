import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import Newsletter from "./Newsletter"

import Text from "../components/text"
import { Link } from "../components/link"
import {
  LeftSymbol,
  RightSymbol,
  LeftSymbolMobile,
} from "../components/synbols"
import { color, space, getBg } from "../theme"
import NavContext from "../utils/nav-context"
import useBreakpoints from "../utils/use-breakpoint"

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto auto auto;
`
const LimitWiderPart = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-evenly;
  justify-content: center;
  margin: 0 auto;
  padding: ${space("normal")} 0;
  ${up("tablet")} {
    padding: ${space("normal")};
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
  }
`

const BG = styled.div<{ bg?: ColorKeys }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: ${getBg};
`

const WhiteSpace = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${color("white")};
  ${up("desktop")} {
    height: 310px;
  }
`
const BlueSpace = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  padding: ${space("normal")} 0;
  ${up("desktop")} {
    padding: ${space("normal")} 0;
    grid-template-rows: 220px 1fr;
  }
`

const Content = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
  padding: 0 2rem;
  justify-content: center;
  margin: 0 auto;
  justify-items: flex-start;
  ${up("desktop")} {
    top: -25px;
    padding: 0;
    grid-template-columns: 1fr 3fr 3fr 1fr;
    grid-gap: 3rem;
  }
`

const YellowSpaceContainer = styled.div`
  position: relative;
  max-width: 1280px;
  width: 100%;

  ${up("desktop")} {
    height: 0px;
    margin: 0 auto;
  }
`
const YellowSpace = styled.div`
  width: 100%;
  height: 466px;
  display: grid;
  grid-template-columns: minmax(auto, 500px);
  grid-template-rows: repeat(3, min-content);
  grid-gap: 2rem;
  align-content: flex-start;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 2rem;
  padding-bottom: 0;
  overflow: hidden;
  background-color: ${color("yellow")};
  ${up("desktop")} {
    padding: 4rem;
    position: absolute;
    top: -187px;
    height: 376px;
    padding-top: 0rem;
    align-content: center;
  }
`

const ExtendedFooter: React.FC = () => {
  const { extendFooter } = React.useContext(NavContext)
  const breakpoint = useBreakpoints({
    config: {
      mobile: 0,
      tablet: 767,
      desktop: 1280,
    },
    defaultValue: "unknown",
  })
  return (
    <Wrapper>
      <WhiteSpace />
      <YellowSpaceContainer>
        <YellowSpace>
          <div
            css={`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              overflow: hidden;
              pointer-events: none;
            `}
          >
            {["mobile", "tablet"].includes(breakpoint) && (
              <LeftSymbolMobile left="0px" style={{ maxWidth: "100%" }} />
            )}
            {["desktop"].includes(breakpoint) && <LeftSymbol left="0px" />}
            {["desktop", "tablet"].includes(breakpoint) && (
              <RightSymbol
                style={{ overflow: "hidden", height: "100%", width: "auto" }}
                right={breakpoint === "desktop" ? "68px" : "-175px"}
              />
            )}
          </div>
          <Text type="h4">{extendFooter.card.title}</Text>
          <Text align="center">{extendFooter.card.copy}</Text>
          <Link to={extendFooter.card.link.to} bg="white" color="black">
            {extendFooter.card.link.copy}
          </Link>
        </YellowSpace>
      </YellowSpaceContainer>
      <BG bg="lightblue" />
      <LimitWiderPart>
        <BlueSpace>
          <div />
          <Content>
            <div />
            <div
              style={{
                display: "grid",
                alignContent: "flex-start",
                gridGap: "1rem",
              }}
            >
              <Text type="h4">{extendFooter.title}</Text>
              <Text>
                Subscribe to our mailing list to receive our popular monthly
                newsletter, hear the latest news from our Founders Workshop and
                a collection of articles, news and product updates directly into
                your inbox.
              </Text>
            </div>
            <Newsletter />
            <div />
          </Content>
        </BlueSpace>
      </LimitWiderPart>
    </Wrapper>
  )
}

export default ExtendedFooter
