import * as React from "react"
import GatsbyLink from "gatsby-link"
import styled, { css } from "styled-components"

import theme, { text, getColor } from "../../theme"

export type ArrowLinkProps = {
  copy?: string
  color?: ColorKeys
  hoverColor?: ColorKeys
  type?: TextKeys
  align?: AlignKeys
  to: string
}

const InternalCustomSimpleTextLink = css<{ color: ColorKeys }>`
  display: block;
  ${text("body")};
  line-height: 1;
  position: relative;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5rem;
  align-items: center;
  justify-items: center;
  text-decoration: none;
  color: ${getColor};
  transition: clip-path 0.2s cubic-bezier(0, 0, 0.2, 1);
`

const GatsbyCustomSimpleTextLink = styled(GatsbyLink)<{ color: ColorKeys }>`
  ${InternalCustomSimpleTextLink}
`
const ExternalCustomSimpleTextLink = styled.a<{ color: ColorKeys }>`
  ${InternalCustomSimpleTextLink}
`

const CustomSimpleTextLink: React.FC<{
  to: string
  color: ColorKeys
}> = props =>
  props.to.startsWith("http") ? (
    <ExternalCustomSimpleTextLink target="__blank" href={props.to} {...props} />
  ) : (
    <GatsbyCustomSimpleTextLink {...props} />
  )

const ShadowLink = styled.span<{ color: ColorKeys }>`
  ${text("body")};
  pointer-events: none;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5rem;
  align-items: center;
  justify-items: center;
  color: ${getColor};
  transition: clip-path 0.2s cubic-bezier(0, 0, 0.2, 1);
  clip-path: inset(0px 100% 0px 0px);
  span {
    transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1);
  }
`

const ArrowContainer = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  &:hover {
    ${ShadowLink} {
      clip-path: inset(0px 0% 0px 0px);
    }
  }
`

const Arrow: React.FC<{ color: ColorKeys }> = ({ color }) => (
  <svg
    width="40px"
    height="12px"
    viewBox="0 0 40 12"
    preserveAspectRatio="xMidYMid meet"
  >
    <line
      strokeWidth="2"
      stroke={theme.colors[color]}
      x1="0"
      y1="6"
      x2="25"
      y2="6"
    ></line>
    <path fill={theme.colors[color]} d="M25,0 L35,6 25,12 25,0 Z"></path>
  </svg>
)

const ArrowLink: React.FC<ArrowLinkProps> = ({
  copy,
  color,
  hoverColor,
  ...props
}) => {
  return (
    <ArrowContainer>
      <CustomSimpleTextLink color={color || "blue"} {...props}>
        <span>{copy || "Read more"}</span>
        <Arrow color={color || "blue"} />
      </CustomSimpleTextLink>
      <ShadowLink aria-hidden="true" color={hoverColor || "rose"} {...props}>
        <span>{copy || "Read more"}</span>
        <Arrow color={hoverColor || "rose"} />
      </ShadowLink>
    </ArrowContainer>
  )
}

export default ArrowLink
