import * as React from "react"
import styled, { withTheme } from "styled-components"
import { up, down } from "styled-breakpoints"

import { color } from "../theme"

type Props = {
  src:
    | string
    | { default: string; mobile: string; tablet: string; desktop: string }
  alt: string
  mw: string | number
  mh: string | number
  tw?: string | number
  th?: string | number
  dw?: string | number
  dh?: string | number
  fit?: FitKeys
  theme: GlobalTheme
}

const D = styled.div<{
  mw: string | number
  mh: string | number
  tw?: string | number
  th?: string | number
  dw?: string | number
  dh?: string | number
}>`
  display: grid;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  width: ${p => (typeof p.mw === "number" ? `${p.mw}px` : p.mw)};
  height: ${p => (typeof p.mh === "number" ? `${p.mh}px` : p.mh)};
  ${up("tablet")} {
    width: ${p =>
      p.tw ? (typeof p.tw === "number" ? `${p.tw}px` : p.tw) : null};
    height: ${p =>
      p.th ? (typeof p.th === "number" ? `${p.th}px` : p.th) : null};
  }
  ${up("desktop")} {
    width: ${p =>
      p.dw ? (typeof p.dw === "number" ? `${p.dw}px` : p.dw) : null};
    height: ${p =>
      p.dh ? (typeof p.dh === "number" ? `${p.dh}px` : p.dh) : null};
  }
  background-color: ${color("white")};
  overflow: hidden;
`

const I = styled.img<{
  mw: string | number
  mh: string | number
  tw?: string | number
  th?: string | number
  dw?: string | number
  dh?: string | number
  fit?: FitKeys
  isInside?: boolean
}>`
  width: ${p => (typeof p.mw === "number" ? `${p.mw}px` : p.mw)};
  height: ${p => (typeof p.mh === "number" ? `${p.mh}px` : p.mh)};
  margin: ${p => (p.isInside ? "0" : "0 auto")};
  object-fit: ${p => p.fit || "cover"};
  ${up("tablet")} {
    width: ${p =>
      p.tw ? (typeof p.tw === "number" ? `${p.tw}px` : p.tw) : null};
    height: ${p =>
      p.th ? (typeof p.th === "number" ? `${p.th}px` : p.th) : null};
  }
  ${up("desktop")} {
    width: ${p =>
      p.dw ? (typeof p.dw === "number" ? `${p.dw}px` : p.dw) : null};
    height: ${p =>
      p.dh ? (typeof p.dh === "number" ? `${p.dh}px` : p.dh) : null};
  }
  width: ${p => (p.isInside ? "100%!important" : undefined)};
  z-index: 1;
`
const P = styled.picture<{
  mw: string | number
  mh: string | number
  tw?: string | number
  th?: string | number
  dw?: string | number
  dh?: string | number
  fit?: FitKeys
}>`
  display: grid;
  width: ${p => (typeof p.mw === "number" ? `${p.mw}px` : p.mw)};
  height: ${p => (typeof p.mh === "number" ? `${p.mh}px` : p.mh)};
  margin: 0 auto;
  object-fit: ${p => p.fit || "cover"};
  img {
    align-self: center;
    justify-self: center;
    margin: 0;
  }
  ${up("tablet")} {
    width: ${p =>
      p.tw ? (typeof p.tw === "number" ? `${p.tw}px` : p.tw) : null};
    height: ${p =>
      p.th ? (typeof p.th === "number" ? `${p.th}px` : p.th) : null};
  }
  ${up("desktop")} {
    width: ${p =>
      p.dw ? (typeof p.dw === "number" ? `${p.dw}px` : p.dw) : null};
    height: ${p =>
      p.dh ? (typeof p.dh === "number" ? `${p.dh}px` : p.dh) : null};
  }
  z-index: 1;
`

const Img: React.FC<Props> = ({
  src,
  alt,
  mw,
  mh,
  tw,
  th,
  dw,
  dh,
  fit,
  ...props
}) =>
  typeof src === "string" ? (
    <I
      src={src}
      alt={alt}
      mw={mw}
      mh={mh}
      tw={tw}
      th={th}
      dw={dw}
      dh={dh}
      fit={fit}
      {...props}
    />
  ) : (
    <P mw={mw} mh={mh} tw={tw} th={th} dw={dw} dh={dh} fit={fit} {...props}>
      <source
        media={down("mobile")(props).replace("@media ", "")}
        srcSet={`${src.mobile}${src.mobile.includes("?") ? "&" : "?"}fm=webp`}
        type="image/webp"
      />
      <source
        media={down("tablet")(props).replace("@media ", "")}
        srcSet={`${src.tablet}${src.tablet.includes("?") ? "&" : "?"}fm=webp`}
        type="image/webp"
      />
      <source
        media={up("desktop")(props).replace("@media ", "")}
        srcSet={`${src.desktop}${src.desktop.includes("?") ? "&" : "?"}fm=webp`}
        type="image/webp"
      />
      <source
        media={down("mobile")(props).replace("@media ", "")}
        srcSet={`${src.mobile}${src.mobile.includes("?") ? "&" : "?"}fm=jpg`}
        type="image/jpg"
      />
      <source
        media={down("tablet")(props).replace("@media ", "")}
        srcSet={`${src.tablet}${src.tablet.includes("?") ? "&" : "?"}fm=jpg`}
        type="image/jpg"
      />
      <source
        media={up("desktop")(props).replace("@media ", "")}
        srcSet={`${src.desktop}${src.desktop.includes("?") ? "&" : "?"}fm=jpg`}
        type="image/jpg"
      />
      <I
        src={src.default}
        alt={alt}
        mw={mw}
        mh={mh}
        tw={tw}
        th={th}
        dw={dw}
        dh={dh}
        fit={fit}
        isInside
        {...props}
      />
    </P>
  )

export default withTheme(Img)
