import React, { FC } from "react"
import { up } from "styled-breakpoints"
import { Helmet } from "react-helmet"
import GatsbyLink from "gatsby-link"
import styled, { ThemeProvider } from "styled-components"

import Navbar from "../blocks/NavBar"
import Footer from "../blocks/Footer"
import ExtendedFooter from "../blocks/ExtendedFooter"
import theme, { color, GlobalStyle } from "../theme"
import Logo from "../components/logo"

const CustomLogoLink = styled(GatsbyLink)`
  line-height: 0;
  text-decoration: none;
`

const Main = styled.main<{ minimal?: boolean }>`
  margin-top: 80px;
  margin-bottom: ${p => (p.minimal ? 100 : 0)}px;
`

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`

const MinimalNav = () => (
  <div
    css={`
      display: grid;
      padding: 0 2rem;
      align-items: center;
      justify-items: flex-start;
      border-bottom: 1px solid ${color("lightgrey")};
      background-color: ${color("white")};
      height: 90px;
    `}
  >
    <CustomLogoLink to="/" aria-label="Link to homepage">
      <Logo />
    </CustomLogoLink>
  </div>
)

const Layout: FC<{ minimal?: boolean }> = ({ minimal = false, children }) => {
  return (
    <React.StrictMode>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="The most flexible payment solution that empowers platforms, fintechs and cryptocurrency businesses to design the economies of tomorrow."
        />
        <meta
          property="og:description"
          content="The most flexible payment solution that empowers platforms, fintechs and cryptocurrency businesses to design the economies of tomorrow."
        />
        {typeof window !== "undefined" && (
          <meta
            property="og:image"
            content={`${window.location.toString()}/sharing.png`}
          />
        )}
        <meta property="og:url" content="https://paybase.io" />
        <meta property="og:site_name" content="Paybase GmbH" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content="/sharing.png" />
        <meta name="twitter:image:alt" content="Paybase image" />
        <meta name="twitter:site" content="@paybase" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          {minimal ? <MinimalNav /> : <Navbar />}
          <Main minimal={minimal}>{children}</Main>
          {!minimal && <ExtendedFooter />}
          <Footer />
          <ModalContainer key={"modal"} id="menu" />
        </>
      </ThemeProvider>
    </React.StrictMode>
  )
}

export default Layout
