import * as React from "react"
import GatsbyLink from "gatsby-link"
import styled, { css } from "styled-components"

import { color, text, getColor } from "../../theme"

export type SimpleTextLinkProps = {
  color?: ColorKeys
  hoverColor?: ColorKeys
  type?: TextKeys
  align?: AlignKeys
  to: string
  underlined?: boolean
  alwaysUnderlined?: boolean
}

const InternalSimpleTextLink = css<SimpleTextLinkProps>`
  ${p => text(p.type || "body")(p)}
  position:relative;
  text-decoration: ${p => (p.alwaysUnderlined ? "underline" : "none")};
  text-align: ${p => p.align || "center"};
  transition: all 0.1s cubic-bezier(0, 0, 0.2, 1);
  color: ${getColor};
  svg {
    fill: ${getColor};
  }

  &:hover {
    text-decoration: ${p => (p.underlined ? "underline" : null)};
    color: ${p => color(p.hoverColor || "yellow")};
    svg {
      fill: ${p => color(p.hoverColor || "yellow")};
    }
  }
`

export const GatsbySimpleTextLink = styled(
  ({ color, hoverColor, type, align, underlined, ...p }) => (
    <GatsbyLink {...p} />
  )
)<SimpleTextLinkProps>`
  ${InternalSimpleTextLink}
`
export const ExternalSimpleTextLink = styled.a<SimpleTextLinkProps>`
  ${InternalSimpleTextLink}
`

export const SimpleTextLink: React.FC<SimpleTextLinkProps> = props =>
  props.to.startsWith("http") ? (
    <ExternalSimpleTextLink href={props.to} target="__blank" {...props} />
  ) : (
    <GatsbySimpleTextLink {...props} />
  )

export default SimpleTextLink
