import * as React from "react"
import GatsbySourceLink from "gatsby-link"
import styled, { css } from "styled-components"

import { color, getColor, getBg, text } from "../../theme"

export type LinkProps = {
  to: string
  color?: ColorKeys
  bg?: ColorKeys
  shadow?: ColorKeys
  hoverColor?: ColorKeys
  hasPadding?: boolean
}
const InternalLink = css<LinkProps>`
  ${text("link")}
  display: grid;
  text-decoration: none;
  text-align: center;
  justify-items: center;
  align-items: center;
  padding: ${p => (p.hasPadding ? 0 : `0.5rem 1.2rem`)};
  color: ${getColor};
  background-color: ${props => getBg({ ...props, bg: props.bg || "yellow" })};
  transition: all 0.1s ease-out;
  &:hover {
    color: ${p => color(p.hoverColor || "white")};
    background-color: ${props => color(props.shadow || "black")};
    p {
      color: ${p => color(p.hoverColor || "white")};
    }
    svg,
    svg path,
    svg circle,
    svg line {
      fill: ${p => color(p.hoverColor || "black")};
    }
  }
`

export const GatsbyLink = styled(GatsbySourceLink)<LinkProps>`
  ${InternalLink}
`
export const ExternalLink = styled.a<LinkProps>`
  ${InternalLink}
`

const Link: React.FC<LinkProps> = props =>
  props.to.startsWith("http") || props.to.startsWith("mailto") ? (
    <ExternalLink href={props.to} target="__blank" {...props} />
  ) : (
    <GatsbyLink {...props} />
  )

export default Link
