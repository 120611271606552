import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import { color, space } from "../theme"
import NavContext from "../utils/nav-context"

import Text from "../components/text"
import Icon from "../components/icon"
import Logo from "../components/logo"
import MiniLogo from "../components/mini-logo"
import { Link, SimpleTextLink } from "../components/link"

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`
const LimitWiderPart = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  justify-items: space-around;
  justify-content: center;
  padding: 0 ${space("normal")};
  ${up("tablet")} {
    margin: 0 auto;
    padding: ${space("large")} ${space("normal")};
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
  }
`

const Part = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  align-items: stretch;
  justify-items: stretch;
  z-index: 1;
`

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${space("normal")};
  justify-content: flex-start;
  align-items: stretch;
  justify-items: stretch;
  background-color: ${color("white")};
  padding: ${space("normal")};
  ${up("desktop")} {
    padding: 0;
    grid-template-columns: auto 1fr;
    justify-content: space-evenly;
    align-items: stretch;
    justify-items: stretch;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-gap: 0.2rem;
  align-items: flex-start;
  justify-items: flex-start;
  a {
    text-decoration: underline;
  }
  ${up("desktop")} {
    padding-left: 5rem;
  }
`

const LinkContainer = styled.div<{ length: number }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${space("small")};
  align-items: flex-start;
  justify-items: flex-start;
  a {
    text-decoration: underline;
  }
  ${up("desktop")} {
    grid-template-columns: repeat(${props => props.length}, auto);
  }
`

const SiteMaps = styled.div<{ length: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${space("small")};
  grid-row-gap: ${space("normal")};
  align-items: flex-start;
  justify-items: flex-start;
  padding: 0;
  padding-bottom: ${space("normal")};
  ${up("tablet")} {
    grid-template-columns: repeat(${props => props.length / 2}, 1fr);
    grid-row-gap: ${space("normal")};
    justify-items: stretch;
  }
  ${up("desktop")} {
    padding: 0;
    grid-template-columns: repeat(${props => props.length}, 1fr);
  }
`

const Menu = styled.div`
  display: grid;
  grid-template-rows: 40px 1fr;
  align-items: flex-start;
  justify-items: stretch;
`
const List = styled.div<{ length: number }>`
  display: grid;
  grid-template-rows: repeat(${props => props.length}, 1fr);
  grid-gap: ${space("xsmall")};
  align-items: flex-start;
  justify-items: flex-start;
`
const LogoContainer = styled.div`
  display: grid;
  grid-template-rows: 100px;
  align-items: center;
  justify-items: flex-start;
  padding: 0;
`
const IconGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${space("small")};
  align-items: center;
  justify-items: center;
`
const Social = styled.div`
  justify-self: stretch;
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: ${space("small")};
  align-items: flex-start;
`
const ButtonGroup = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: ${space("small")};
  align-items: ce;
  justify-items: stretch;
`

const BG = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: ${color("black")};
`

const CLink = styled(Link)`
  border: 1px solid ${color("black")};
  &:hover {
    border: 1px solid ${color("white")};
  }
`

const Footer: React.FC = () => {
  const { siteMap, links } = React.useContext(NavContext)
  return (
    <Wrapper>
      <Part>
        <BG />
        <LimitWiderPart>
          <LogoContainer>
            <Logo color="white" />
          </LogoContainer>
          <SiteMaps length={siteMap.length + 1}>
            {siteMap.map((menu, idx) => (
              <Menu key={`menu-footer-${idx}`}>
                <Text color={menu.color}>{menu.title}</Text>
                <List length={menu.links.length}>
                  {menu.links.map((link, idx) => (
                    <SimpleTextLink
                      key={`menu-footer-link-${idx}`}
                      to={link.to}
                      color="white"
                    >
                      {link.copy}
                    </SimpleTextLink>
                  ))}
                </List>
              </Menu>
            ))}
            <Social>
              <IconGroup>
                <SimpleTextLink
                  color="white"
                  aria-label="youtube link"
                  to="https://www.youtube.com/channel/UCOpLjdsV1tAEKEgN-VEc65A/videos"
                >
                  <Icon color="white" hoverColor="yellow" name="youtube" />
                </SimpleTextLink>
                <SimpleTextLink
                  color="white"
                  aria-label="linkedin link"
                  to="https://www.linkedin.com/company/paybase/"
                >
                  <Icon color="white" hoverColor="yellow" name="linkedin" />
                </SimpleTextLink>
                <SimpleTextLink
                  color="white"
                  aria-label="twitter link"
                  to="https://twitter.com/paybase"
                >
                  <Icon color="white" hoverColor="yellow" name="twitter" />
                </SimpleTextLink>
              </IconGroup>
              <ButtonGroup>
                <CLink to="https://trustshare.co">Get in touch</CLink>
                <CLink to="https://console.paybase.io/signin">Login</CLink>
              </ButtonGroup>
            </Social>
          </SiteMaps>
        </LimitWiderPart>
      </Part>
      <LimitWiderPart>
        <Container>
          <MiniLogo />
          <Content>
            <Text type="mobileBody">
              &copy; 2016-{new Date().getFullYear()} Paybase GmbH
            </Text>
            <LinkContainer length={links.length}>
              {links.map((link, idx) => (
                <SimpleTextLink
                  type="mobileBody"
                  key={`menu-footer-link-container-${idx}`}
                  to={link.to}
                  underlined
                  hoverColor="blue"
                >
                  {link.copy}
                </SimpleTextLink>
              ))}
            </LinkContainer>
            <Text type="mobileBody">
              Design by{" "}
              <SimpleTextLink
                type="mobileBody"
                to="https://www.point.studio/#home"
                hoverColor="blue"
              >
                Point Studio
              </SimpleTextLink>
            </Text>
          </Content>
        </Container>
      </LimitWiderPart>
    </Wrapper>
  )
}

export default Footer
