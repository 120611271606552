import { useCallback, useState, useEffect } from "react"

export type Props = {
  defaultValue: string
  config: {
    [key: string]: number
  }
}

const useBreakpoints: (props: Props) => string = ({ config, defaultValue }) => {
  const [state, setState] = useState<string>(defaultValue)
  const listener = useCallback(() => {
    const newState = Object.entries(config).reduce(
      (acc, [k, v]) => (window.innerWidth > v ? k : acc),
      Object.keys(config)[0]
    )
    setState(newState)
  }, [config])
  useEffect(() => {
    listener()
  }, [])
  useEffect(() => {
    window.addEventListener("resize", listener)
    return () => {
      window.removeEventListener("resize", listener)
    }
  }, [])
  return state
}

export default useBreakpoints
