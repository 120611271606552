import * as React from "react"
import styled from "styled-components"
import GatsbyLink from "gatsby-link"

import { MenuMap } from "../../utils/nav-context"
import { Link, SimpleTextLink } from "../../components/link"
import Text from "../../components/text"
import { color, space } from "../../theme"

import Logo from "../../components/logo"

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: 95px;
  align-items: center;
  justify-items: flex-start;
  padding: 0 2rem;
  border-bottom: 1px solid ${color("lightgrey")};
  background-color: ${color("white")};
  z-index: 99;
`
const Nav = styled.nav<{ size: number }>`
  align-self: stretch;
  display: grid;
  grid-template-columns: repeat(${p => p.size}, 1fr);
  align-items: center;
  justify-items: center;
  justify-content: space-evenly;
  grid-gap: 2rem;
  padding: 0 2rem;
`

const CustomText = styled(Text)`
  grid-row: 2;
  transition: all 0.3s ease-out;
`

const Svg = styled.svg`
  grid-row: 3;
  path {
    transition: all 0.3s ease-out;
  }
`
const Filler = styled.div<{ h: number; row: number }>`
  height: ${p => p.h}px;
  grid-row: ${p => p.row};
`

const SubmenuWrapper = styled.div`
  grid-row: 4;
  position: relative;
  top: -24px;
  width: 0;
  height: 0;
`
const SubmenuContainer = styled.div<{ size: number }>`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: max-content;
  grid-template-rows: repeat(${p => p.size * 40}px, 1fr);
  overflow: hidden;
  height: 0;
  transform: translateX(-50%);
  transition: all 0.3s ease-out;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
`

const SubmenuItem = styled(SimpleTextLink)`
  line-height: 41.4px;
  padding: 0.2rem 2rem;
  vertical-align: middle;
  border: 0.5px solid ${color("lightgrey")};
  background-color: ${color("white")};
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:first-child {
    margin-top: ${space("small")};
  }
  &:last-child {
    margin-bottom: ${space("small")};
  }
  &:hover {
    border: 0.5px solid ${color("black")};
    color: ${color("white")};
    background-color: ${color("black")};
  }
`

const NavItemContainer = styled.div<{ size: number }>`
  display: grid;
  grid-gap: 5px;
  align-items: center;
  justify-items: center;

  &:hover {
    ${CustomText} {
      color: ${color("blue")};
    }
    ${Svg} path {
      stroke: ${color("blue")};
    }
    ${SubmenuContainer} {
      height: ${p =>
        p.size * (43 + 6.4) + 16 * 2}px; /* 35px (item height) + .5rem (8px) */
    }
  }
`

const NavItemContainerLink = styled(SimpleTextLink)`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  justify-items: center;

  &:hover {
    ${CustomText} {
      color: ${color("blue")};
    }
    ${Svg} path {
      stroke: ${color("blue")};
    }
  }
`
const NavItemContainerText = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  justify-items: center;

  &:hover {
    ${CustomText} {
      color: ${color("blue")};
    }
    ${Svg} path {
      stroke: ${color("blue")};
    }
  }
`

const CustomLogoLink = styled(GatsbyLink)`
  line-height: 0;
  text-decoration: none;
`

const Down = () => (
  <Svg width="12px" height="12px">
    <path strokeWidth={1} stroke="#d1cce1" fill="none" d="M0,0 L6,6 L12,0" />
  </Svg>
)

const Submenu: React.FC<{ menu: MenuMap[] }> = ({ menu }) => {
  return (
    <SubmenuWrapper>
      <SubmenuContainer size={menu.length}>
        {menu.map((item, idx) => (
          <SubmenuItem
            key={`sub_menu_item_${idx}`}
            hoverColor="blue"
            to={item.to!}
          >
            {item.copy}
          </SubmenuItem>
        ))}
      </SubmenuContainer>
    </SubmenuWrapper>
  )
}
const NavItem: React.FC<MenuMap> = ({ copy, to, submenu }) => {
  return (
    <NavItemContainer size={submenu ? submenu.length : 0}>
      {to ? (
        <NavItemContainerLink underlined={false} to={to}>
          <CustomText>{copy}</CustomText>
          {submenu ? <Down /> : <Filler h={12} row={3} />}
        </NavItemContainerLink>
      ) : (
        <NavItemContainerText>
          <CustomText>{copy}</CustomText>
          {submenu ? <Down /> : <Filler h={12} row={3} />}
        </NavItemContainerText>
      )}
      {submenu ? <Submenu menu={submenu} /> : <Filler h={0} row={4} />}
    </NavItemContainer>
  )
}

export const NavBar: React.FC<{ menuMap: MenuMap[] }> = ({ menuMap }) => {
  return (
    <Container>
      <CustomLogoLink to="/" aria-label="Link to homepage">
        <Logo />
      </CustomLogoLink>
      <Nav size={menuMap.length} role="navigation" aria-label="main menu">
        {menuMap.map((item, idx) => (
          <NavItem key={`nav_item_${idx}`} {...item} />
        ))}
      </Nav>
      <Link to="https://trustshare.co">Get in touch</Link>
    </Container>
  )
}
export default NavBar
