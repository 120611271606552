import * as React from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"
import { useTransition, animated } from "react-spring"

import { MenuMap } from "../../utils/nav-context"
import { SimpleTextLink } from "../../components/link"
import Text from "../../components/text"
import Icon from "../../components/icon"
import { color, space } from "../../theme"

import Logo from "../../components/logo"

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 80px;
  align-items: center;
  justify-items: flex-start;
  padding: 0 2rem;
  border-bottom: 1px solid ${color("lightgrey")};
  background-color: ${color("white")};
  z-index: 99;
`
const MenuIcon = styled(Icon)`
  cursor: pointer;
`

const ModalContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${color("white")};
  display: grid;
  align-items: center;
  justify-items: center;
  overflow-y: scroll;
  padding-top: ${space("large")};
`

const Sub = styled.div`
  display: grid;
`

const MenuItem = styled.div`
  display: grid;
  grid-gap: ${space("small")};
  padding: ${space("small")};
  &:last-child {
    padding-bottom: ${space("large")};
  }
`

const Close = styled(Icon).attrs({ name: "cross" })`
  position: absolute;
  top: ${space("normal")};
  right: ${space("normal")};
  cursor: pointer;
`

const CustomLogoLink = styled(SimpleTextLink)`
  line-height: 0;
  text-decoration: none;
`

const Modal: React.FC<{
  menuMap: MenuMap[]
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setShow, menuMap }) => {
  const [s, set] = React.useState(true)
  const transitions = useTransition(s, null, {
    from: { position: "absolute", transform: "translateY(100%)", opacity: 0 },
    enter: { opacity: 1, transform: "translateY(0%)" },
    // @ts-ignore
    leave: () => async next => {
      await next({ opacity: 0, transform: "translateY(100%)" })
      setShow(false)
    },
  })
  return transitions.map(
    // @ts-ignore
    ({ item, key, props }) =>
      item && (
        <ModalContainer key={key} style={props}>
          <Close key={`menu_item_close`} onClick={() => set(false)} />
          {menuMap.map((menu, idx) => (
            <MenuItem key={`menu_item_${idx}`}>
              {menu.to ? (
                <SimpleTextLink
                  type="h5"
                  key={`menu-mobile-link-${idx}`}
                  to={menu.to}
                  color="black"
                  hoverColor="rose"
                  align="center"
                >
                  {menu.copy}
                </SimpleTextLink>
              ) : (
                <Text
                  type="h5"
                  key={`menu-mobile-text-${idx}`}
                  color="black"
                  align="center"
                >
                  {menu.copy}
                </Text>
              )}

              {menu.submenu && (
                <Sub key={`menu_sub_item_${idx}`}>
                  {menu.submenu.map((sub, jdx) =>
                    sub.to ? (
                      <SimpleTextLink
                        key={`menu-mobile_sub-${jdx}`}
                        to={sub.to}
                        hoverColor="blue"
                        align="center"
                      >
                        {sub.copy}
                      </SimpleTextLink>
                    ) : (
                      <Text
                        type="h5"
                        key={`menu-mobile_sub-text-${jdx}`}
                        color="black"
                        align="center"
                      >
                        {menu.copy}
                      </Text>
                    )
                  )}
                </Sub>
              )}
            </MenuItem>
          ))}
        </ModalContainer>
      )
  )
}

export const MobileBar: React.FC<{ menuMap: MenuMap[] }> = ({ menuMap }) => {
  const modal = document.querySelector("#menu")
  const [show, setShow] = React.useState(false)
  const showModal = () => {
    if (modal) setShow(!show)
  }
  return (
    <Container>
      <CustomLogoLink to="/" aria-label="Link to homepage">
        <Logo />
      </CustomLogoLink>
      <MenuIcon name="menu" onClick={showModal} />
      {show &&
        modal &&
        createPortal(
          <Modal
            key={`modal_mobile_nav`}
            menuMap={menuMap}
            setShow={setShow}
          />,
          modal
        )}
    </Container>
  )
}
export default MobileBar
