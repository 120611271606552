import * as React from "react"
import styled from "styled-components"

type Props = { left?: string; right?: string; style?: any }
const SVG = styled.svg<Props>`
  bottom: 0;
  position: absolute;
  left: ${p => (p.left ? p.left : null)};
  right: ${p => (p.right ? p.right : null)};
`
export const LeftSymbolMobile: React.FC<Props> = props => (
  <SVG {...props} viewBox="0 0 340 160" width="340" height="160">
    <g fill="none">
      <path
        fill="#FF324B"
        d="M0 0h100v40H0zM100 0h40v40h-40zM140 0h40v40h-40zM180 0h40v40h-40zM220 0h40v40h-40zM260 0h40v40h-40zM300 0h10c16.569 0 30 13.431 30 30v10h-40V0zM300 40h40v40h-40zM300 80h40v10c0 16.569-13.431 30-30 30h-10V80zM260 80h40v40h-40zM220 80h40v40h-40zM180 80h40v40h-40zM170 80h10v40h-40v-10c0-16.569 13.431-30 30-30zM140 120h40v40h-40z"
      />
      <circle fill="#FDDD00" cx="182" cy="20" r="15" />
    </g>
  </SVG>
)

export const RightSymbol: React.FC<Props> = props => (
  <SVG
    {...props}
    viewBox="0 0 280 376"
    width="280"
    height="376"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      fill="#00BA74"
      d="M120 40h40v40h-40zM120 0h40v40h-40zM120 160h40v40h-40zM120 200h40v40h-40zM120 80h40v40h-40zM120 120h40v40h-40z"
    />
    <path
      fill="#3636FF"
      d="M120 240h40v40h-40zM160 240h40v40h-40zM200 240h40v40h-40zM240 240h10c16.569 0 30 13.431 30 30v10h-40v-40zM240 280h40v40h-40zM240 320h40v40h-40zM240 360h40v16h-40zM80 240h40v40H80zM40 240h40v40H40zM30 240h10v40H0v-10c0-16.569 13.431-30 30-30zM0 280h40v40H0zM0 320h40v40H0zM0 360h40v16H0z"
    />
    <circle fill="#FDDD00" cx="140" cy="79" r="15" />
  </SVG>
)

export const LeftSymbol: React.FC<Props> = props => (
  <SVG {...props} viewBox="0 0 280 240" width="280" height="240">
    <g fill="none">
      <path
        fill="#FF324B"
        d="M0 0h40v40H0zM40 0h40v40H40zM80 0h40v40H80zM120 0h40v40h-40zM160 0h40v40h-40zM200 0h40v40h-40zM240 0h10c16.569 0 30 13.431 30 30v10h-40V0zM240 40h40v40h-40zM240 80h40v40h-40zM240 120h40v10c0 16.569-13.431 30-30 30h-10v-40zM200 120h40v40h-40zM160 120h40v40h-40zM120 120h40v40h-40zM110 120h10v40H80v-10c0-16.569 13.431-30 30-30zM80 160h40v40H80zM80 200h40v40H80z"
      />
      <circle fill="#FDDD00" cx="122" cy="20" r="15" />
    </g>
  </SVG>
)

export const LeftSymbolAbout: React.FC<Props> = props => (
  <SVG {...props} width="280px" height="410px" viewBox="0 0 280 410">
    <g fill="none">
      <path
        fill="#00BA74"
        d="M0 0h40v40H0zM40 0h40v40H40zM80 0h40v40H80zM120 0h40v40h-40zM160 0h40v40h-40zM200 0h40v40h-40zM240 0h10c16.569 0 30 13.431 30 30v10h-40V0zM240 40h40v40h-40zM240 80h40v40h-40zM240 120h40v10c0 16.569-13.431 30-30 30h-10v-40zM200 120h40v40h-40zM160 120h40v40h-40zM120 120h40v40h-40zM110 120h10v40H80v-10c0-16.569 13.431-30 30-30zM80 160h40v40H80zM80 200h40v250H80z"
      />
      <circle fill="#FDDD00" cx="122" cy="20" r="15" />
    </g>
  </SVG>
)
export const RightSymbolAbout: React.FC<Props> = props => (
  <SVG {...props} width="400px" height="720px" viewBox="0 0 400 720">
    <g fill="none">
      <path
        fill="#FF324B"
        d="M80 0h40v520H80zM80 520h40v40c-20 0-40-14-40-40zM120 520h280v40H120z"
      />
      <circle fill="#FDDD00" cx="240" cy="540" r="15" />
    </g>
  </SVG>
)
export const AboutMobile: React.FC<Props> = props => (
  <SVG {...props} width="100%" height="60px">
    <g fill="none">
      <rect fill="#ff88a4" width="100%" height="30" />
      <circle fill="#FDDD00" cx="284" cy="15" r="12" />
    </g>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#ff88a4" x="0" y="0" width="100%" height="30"></rect>
      <circle fill="#FDDD00" cx="284" cy="15" r="12"></circle>
    </g>
  </SVG>
)
