import * as React from "react"

export default () => (
  <svg width="73px" height="40px" viewBox="0 0 73 40">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-91.000000, -540.000000)" fill="#303030">
        <g transform="translate(0.000000, 477.000000)">
          <g transform="translate(91.000000, 63.000000)">
            <path d="M37.3612646,35 L70.6387354,35 C71.9426809,35 73,36.1180124 73,37.5 C73,38.8793996 71.9426809,40 70.6387354,40 L37.3612646,40 C36.059761,40 35,38.8793996 35,37.5 C35.0024418,36.1180124 36.059761,35 37.3612646,35"></path>
            <path d="M14.6672726,20.3691948 C18.4429699,20.3691948 21.1030639,19.6283322 22.6401173,18.1515135 C24.1771707,16.6697884 24.9432183,14.8298979 24.9432183,12.6318421 C24.9432183,10.4313331 24.1746916,8.59144268 22.6401173,7.11462395 C21.1030639,5.63289883 18.445449,4.89203628 14.6672726,4.89448339 L5.0507724,4.89448339 L5.0507724,20.3691948 L14.6672726,20.3691948 Z M0.722231711,39.2587368 C0.246240982,38.7730057 -0.0165455666,38.1155515 0.000808262051,37.4360186 L0.000808262051,2.62283805 C-0.0115873298,1.93594562 0.2487201,1.27358505 0.722231711,0.77313485 C1.17591037,0.272684647 1.82296027,-0.0118850766 2.50223871,0 L15.1655754,0 C18.6859235,0 21.5542635,0.606318116 23.7780326,1.82064581 C25.9993227,3.0374267 27.5933958,4.59029424 28.5552938,6.486608 C30.4815687,10.3626439 30.4815687,14.9010403 28.5552938,18.7770762 C27.5933958,20.67339 25.9993227,22.2287107 23.7780326,23.4405852 C21.5542635,24.6524597 18.6859235,25.258397 15.1655754,25.2608502 L5.0507724,25.2608502 L5.0507724,37.4360186 C5.06316799,38.1204579 4.79294409,38.7803652 4.29959953,39.2587368 C3.82608792,39.7395615 3.17407979,40.0094121 2.49480135,39.9995993 C1.82296027,40.0118653 1.17838949,39.7420147 0.717273474,39.2587368 L0.722231711,39.2587368 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
