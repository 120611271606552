import styled from "styled-components"
import { getText, getColor } from "../theme"

export type Props = {
  type?: TextKeys
  mobileType?: TextKeys
  color?: ColorKeys
  align?: AlignKeys
  weight?: WeightKeys
}

const Text = styled.p<Props>`
  ${getText};
  transition: all 0.3s ease-out;
  color: ${p => getColor(p)};
  text-align: ${p => p.align || null};
  font-weight: ${p => p.weight || null};
`

export default Text
