import * as React from "react"
import GatsbyLink from "gatsby-link"
import styled, { css } from "styled-components"

import { color, text, getColor } from "../../theme"

export type TextLinkProps = {
  color?: ColorKeys
  hoverColor?: ColorKeys
  type?: TextKeys
  align?: AlignKeys
  to: string
}
const InternalTextLink = css<TextLinkProps>`
  ${p => text(p.type || "body")(p)}
  cursor:pointer;
  position: relative;
  text-decoration: none;
  text-align: ${p => p.align || "center"};
  transition: all 0.1s cubic-bezier(0, 0, 0.2, 1);
  color: ${getColor};
  padding-bottom: 0.2rem;
  svg {
    fill: ${getColor};
  }

  &:hover {
    text-decoration: underline;
    color: ${p => color(p.hoverColor || "yellow")};
    svg {
      fill: ${p => color(p.hoverColor || "yellow")};
    }
  }
`

const GatsbyTextLink = styled(GatsbyLink)<TextLinkProps>`
  ${InternalTextLink}
`
const ExternalTextLink = styled.a<TextLinkProps>`
  ${InternalTextLink}
`

const TextLink: React.FC<TextLinkProps> = props =>
  props.to.startsWith("http") ? (
    <ExternalTextLink href={props.to} target="__blank" {...props} />
  ) : (
    <GatsbyTextLink {...props} />
  )

export default TextLink
