import * as React from "react"

import useBreakpoints from "../../utils/use-breakpoint"
import NavContext from "../../utils/nav-context"

import NavBar from "./NavBar"
import MobileBar from "./MobileBar"

const NavBarContainer: React.FC<{}> = () => {
  const { menuMap } = React.useContext(NavContext)
  const breakpoint = useBreakpoints({
    defaultValue: "unknown",
    config: { mobile: 0, tablet: 768, desktop: 1145 },
  })
  switch (breakpoint) {
    case "unknown":
      return null
    case "desktop":
      return <NavBar menuMap={menuMap} />
    default:
      return <MobileBar menuMap={menuMap} />
  }
}
export default NavBarContainer
