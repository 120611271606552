import { createContext } from "react"

export type ExtendFooter = {
  copy: string
  title: string
  copyTitle: string
  card: {
    copy: string
    title: string
    link: LinkType
  }
}
export type MenuMap = {
  copy: string
  to?: string
  submenu?: MenuMap[]
}

export type SiteMap = {
  title: string
  color: ColorKeys
  links: LinkType[]
}[]
export type ContextType = {
  extendFooter: ExtendFooter
  menuMap: MenuMap[]
  siteMap: SiteMap
  links: LinkType[]
}
export const defaultValue: ContextType = {
  extendFooter: {
    copy:
      "GDPR Donec ullamcorper nulla non metus auctor fringilla. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.",
    title: "Stay in the loop_",
    copyTitle:
      "A collection of articles, news and product updates directly into your inbox each week. ",
    card: {
      copy:
        "Speak with one of our team to understand how we can help develop a better payment solution just for you.",
      title: "Get started today_",
      link: { copy: "Get in touch", to: "https://trustshare.co" },
    },
  },
  menuMap: [
    {
      copy: "Our solution",
      submenu: [
        { copy: "Why choose us", to: "/why-choose-us" },
        { copy: "What we offer", to: "/what-we-offer" },
        { copy: "How we do it", to: "/how-we-do-it" },
      ],
    },
    {
      copy: "Who is it for?",
      submenu: [
        { copy: "Marketplaces", to: "/who-is-it-for/marketplaces" },
        { copy: "Gig economy", to: "/who-is-it-for/gig-economy" },
        { copy: "Sharing economy", to: "/who-is-it-for/sharing-economy" },
        { copy: "FinTech", to: "/who-is-it-for/fintechs" },
        { copy: "Escrow", to: "https://trustshare.co" },
        { copy: "Blockchain", to: "/who-is-it-for/blockchain" },
      ],
    },
    {
      copy: "Pricing",
      to: "/pricing",
      // submenu: [{ copy: "Pricing", to: "/pricing" }],
    },
    {
      copy: "What's new",
      submenu: [
        { copy: "Blog", to: "/blog" },
        { copy: "Events", to: "/events" },
        { copy: "Whitepaper", to: "https://trustshare.co" },
      ],
    },
    {
      copy: "Developers",
      to: "https://docs.trustshare.io/",
      // submenu: [{ copy: "Dev docs", to: "/" }],
    },
    {
      copy: "About Us",
      to: "/about-us",
      // submenu: [{ copy: "Who are we?", to: "/about-us" }],
    },
  ],
  siteMap: [
    {
      title: "Our Solution",
      color: "lightblue",
      links: [
        { copy: "Why choose us", to: "/why-choose-us" },
        { copy: "What we offer", to: "/what-we-offer" },
        { copy: "How we do it", to: "/how-we-do-it" },
      ],
    },
    {
      title: "Who is it for?",
      color: "rose",
      links: [
        { copy: "Marketplaces", to: "/who-is-it-for/marketplaces" },
        { copy: "Gig economy", to: "/who-is-it-for/gig-economy" },
        { copy: "Sharing economy", to: "/who-is-it-for/sharing-economy" },
        { copy: "FinTech", to: "/who-is-it-for/fintechs" },
        { copy: "Escrow", to: "https://trustshare.co" },
        { copy: "Blockchain", to: "/who-is-it-for/blockchain" },
      ],
    },
    {
      title: "Help",
      color: "green",
      links: [
        { copy: "Pricing", to: "/pricing" },
        { copy: "Developers", to: "https://docs.trustshare.io/" },
        // { copy: "Contact us", to: "https://trustshare.co" },
      ],
    },
    {
      title: "What's new",
      color: "yellow",
      links: [
        { copy: "Blog", to: "/blog" },
        { copy: "Events", to: "/events" },
        { copy: "Whitepaper", to: "https://trustshare.co" },
      ],
    },
    {
      title: "About Paybase",
      color: "lightblue",
      links: [{ copy: "About us", to: "/about-us" }],
    },
  ],
  links: [
    { copy: "Privacy policy", to: "/privacy-policy" },
    { copy: "Complaints", to: "/forms/complaints" },
    { copy: "Emoney recovery", to: "/forms/recovery" },
    { copy: "Security incident", to: "/forms/securityIncident" },
    { copy: "Delayed or defective transaction", to: "/forms/transaction" },
    { copy: "Prohibited Industries", to: "https://storage.googleapis.com/paybase-website/assets/Prohibited%20Industries%20Policy.pdf"}
  ],
}
const FooterContext = createContext(defaultValue)
export default FooterContext
