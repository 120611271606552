import * as React from "react"
import styled from "styled-components"

import Icon from "../icon"
import Text from "../text"
import Link from "./link"

export type IconLinkProps = {
  icon: IconKeys
  copy?: string
  to: string
  size?: IconSize
  bg?: ColorKeys
  shadow?: ColorKeys
  color?: ColorKeys
  hoverColor?: ColorKeys
  hasPadding?: boolean
}

const Container = styled.div<{
  bg?: ColorKeys
  shadow?: ColorKeys
}>`
  display: grid;
  text-align: center;
  grid-auto-flow: column;
  grid-gap: 12px;
  justify-items: center;
  align-items: center;
`

const IconLink: React.FC<IconLinkProps> = ({
  copy,
  to,
  icon,
  bg,
  shadow,
  color,
  hoverColor,
  size,
  ...props
}) => (
  <Link
    color={color}
    to={to}
    bg={bg}
    shadow={shadow}
    hoverColor={hoverColor}
    {...props}
  >
    <Container>
      <Icon name={icon} color={color} hoverColor={hoverColor} size={size} />
      {copy && (
        <Text type="link" color={color}>
          {copy}
        </Text>
      )}
    </Container>
  </Link>
)

export default IconLink
