import styled from "styled-components"

const Anchor = styled.div<{ top?: string }>`
  position: absolute;
  top: ${p => p.top || 0};
  left: 0;
  height: 0;
  width: 0;
  pointer-events: none;
  user-select: none;
`

export default Anchor
