import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import { navigate } from "gatsby"

import { color, text } from "../theme"
import Text from "../components/text"
import { UTMContext } from "../components/utmcontext"
import { SimpleTextLink } from "../components/link"
import Anchor from "../components/anchor"

type Props = {}

const ContactForm = styled.form`
  position: relative;
  display: grid;
  grid-gap: 0.5rem;
  margin: 0 auto;
  max-width: 100%;
  grid-template-columns: 1fr;
  ${up("desktop")} {
    grid-template-columns: 1fr 1fr;
  }
`
const Input = styled.input`
  ${text("link")}
  justify-self: stretch;
  border: none;
  height: 35px;
  line-height: 35px;
  padding: 0 1rem;
  background-color: ${color("white")};
`

const Email = styled(Input)`
  ${up("desktop")} {
    grid-column: 1 / 3;
    grid-row: 2;
  }
`

const Button = styled.button`
  grid-column: 1;
  cursor: pointer;
  display: block;
  border: none;
  outline: none;
  height: 35px;
  padding: 0;
  line-height: 35px;
  background-color: ${color("yellow")};
  box-shadow: 0px 0px 0px 0px ${color("trueblack")};
  transition: all 0.3s ease-out;
  &:hover {
    color: ${color("white")};
    background-color: ${color("black")};
  }
  ${up("desktop")} {
    grid-column: 1 / 3;
    grid-row: 4;
    padding: 0 2rem;
  }
`

const Loading = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${color("black")};
    animation: loader-anim 1.3s infinite;
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
  }
`
const Check = styled.input`
  margin: 0.5rem;
  margin-left: 0;
`

const Legal = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-column: 1;
  ${up("desktop")} {
    grid-column: 1 / 3;
    grid-row: 3;
  }
`
const PLink = styled(SimpleTextLink)`
  font-size: 0.6rem;
`

// https://get.paybase.io/escrow/?utm_source=Search&utm_medium=Escrow&utm_campaign=Providers&utm_term=RS1
const Newsletter: React.FC<Props> = () => {
  const [loading, setLoading] = React.useState(false)
  const params = React.useContext(UTMContext)
  const onsubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const data = {
      firstname: e.currentTarget.firstname.value,
      lastname: e.currentTarget.lastname.value,
      email: e.currentTarget.email.value,
      medium: e.currentTarget.medium.value,
      cf_term: e.currentTarget.cf_term.value,
      utm_campaign: e.currentTarget.utm_campaign.value,
      utm_source: e.currentTarget.utm_source.value,
    }
    setLoading(true)
    fetch("/.netlify/functions/newsletter", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).then(r => {
      setLoading(false)
      if (r.status === 200) navigate("/thank-you-enquiry-newsletter")
      else
        alert("Something went wrong, please try again later or contact paybase")
    })
    e.preventDefault()
  }
  return (
    <ContactForm onSubmit={onsubmit}>
      <Anchor id="newsletter" top="-125px" />
      <Input
        required
        id="firstname"
        name="firstname"
        type="text"
        placeholder="first name"
      />
      <Input
        required
        id="lastname"
        name="lastname"
        type="text"
        placeholder="last name"
      />
      <Email
        required
        id="email"
        name="email"
        type="email"
        placeholder="email address"
      />
      <input type="hidden" name="medium" value={params.utm_medium || ""} />
      <input
        type="hidden"
        name="utm_campaign"
        value={params.utm_campaign || ""}
      />
      <input type="hidden" name="utm_source" value={params.utm_source || ""} />
      <input type="hidden" name="cf_term" value={params.utm_term || ""} />
      <Legal>
        <Text type="smallcopy">
          <Check type="checkbox" name="agree" required={true} />
          By ticking this box, you agree to us contacting you by email and
          storing your contact details for future contact about the Paybase
          Limited products and services.
        </Text>
        <Text type="smallcopy">
          Your contact details will be accessible by employees of Paybase
          Limited, but will never be shared with or sold to third parties.
          Submitting your details also indicates that you have read and agree to
          our{" "}
          <PLink to="/privacy-policy" color="blue" hoverColor="red">
            privacy policy
          </PLink>
          . You can, of course, opt out of these communications at any time!
        </Text>
      </Legal>
      <Button type="submit">{loading ? <Loading /> : "Subscribe"}</Button>
    </ContactForm>
  )
}

export default Newsletter
