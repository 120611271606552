import { PropsWithChildren } from "react"
import reset from "styled-reset"
// import get from "lodash/fp/get"
import { createGlobalStyle } from "styled-components"
import { up } from "styled-breakpoints"

import { default as fonts } from "./fonts"

function get<T, K extends keyof T>(path: K, arr: T): T[keyof T] {
  return arr[path]
}

export const theme: GlobalTheme = {
  breakpoints: {
    mobile: "0px",
    tablet: "768px",
    desktop: "1280px",
  },
  colors: {
    white: "#fff",
    trueblack: "#000",
    black: "#303030",
    grey: "#595959",
    lightgrey: "#d1cce1",
    blue: "#401eff",
    yellow: "#fddd00",
    lightblue: "#89e6ff",
    purple: "#5c3caf",
    lavender: "#f3e1ff",
    green: "#b1f4c4",
    darkgreen: "#00ba74",
    rose: "#ff88a4",
    red: "#ff324b",
    transparent: "transparent",
  },
  texts: {
    body: `
      font-size: 1rem;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
    `,
    mobileBody: `
      font-size: .9rem;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    `,
    smallcopy: `
      font-size: .6rem;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    `,
    link: `
      font-family: 'SpaceGrotesk Medium';
      font-size: .75rem;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83;
      letter-spacing: normal;
    `,
    h4: `
      font-size: 1.8rem;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
    `,
    h5: `
      font-size: 1.25rem;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
    `,
    headline: p => `
      font-size: 1.8rem;
      ${up("tablet")(p)}{
        font-size: 2.75rem;
      }
      font-style: normal;
      line-height: 1.05;
      letter-spacing: normal;
    `,
  },
  spaces: {
    xsmall: ".5rem",
    small: "1rem",
    normal: "2rem",
    large: "4rem",
    xlarge: "6rem",
  },
}

export const GlobalStyle = createGlobalStyle`
  ${reset}
  * {box-sizing:border-box;}

  @font-face {
    font-family: 'SpaceGrotesk Light';
    src: local("SpaceGrotesk Light"), local("SpaceGrotesk-Light"), url(https://paybase.imgix.net/assets/fonts/SpaceGrotesk-Light.woff2) format("woff2"), url(https://paybase.imgix.net/assets/fonts/SpaceGrotesk-Light.woff) format("woff");
  }
  @font-face {
    font-family: 'SpaceGrotesk';
    src: local("SpaceGrotesk"), url(https://paybase.imgix.net/assets/fonts/SpaceGrotesk-Regular.woff2) format("woff2"), url(https://paybase.imgix.net/assets/fonts/SpaceGrotesk-Regular.woff) format("woff");
  }
  @font-face {
    font-family: 'SpaceGrotesk Medium';
    src: local("SpaceGrotesk Medium"), local("SpaceGrotesk-Medium"), url(https://paybase.imgix.net/assets/fonts/SpaceGrotesk-Medium.woff2) format("woff2"), url(https://paybase.imgix.net/assets/fonts/SpaceGrotesk-Medium.woff) format("woff");
  }
  @font-face {
    font-family: 'SpaceGrotesk Bold';
    src: local("SpaceGrotesk Bold"), local("SpaceGrotesk-Bold"), url(https://paybase.imgix.net/assets/fonts/SpaceGrotesk-Bold.woff2) format("woff2"), url(https://paybase.imgix.net/assets/fonts/SpaceGrotesk-Bold.woff) format("woff");
  }

  @keyframes loader-anim {
    0%{ transform: translate(-25px, 0px); }
    50% { transform: translate(25px, 0px); }
    100% { transform: translate(-25px, 0px); }
  }

  body {
    display:grid;
    grid-template-columns: 1fr;
    font-family: 'SpaceGrotesk';
    font-weight: normal;
    line-height: 1.5;
    font-size: 16px;
  }
`
export const color = (color: ColorKeys) => (props: { theme: GlobalTheme }) =>
  get(color, props.theme.colors)
export const text = (type: TextKeys) => (props: { theme: GlobalTheme }) => {
  const fn = get(type, props.theme.texts)
  if (typeof fn === "string") return fn
  return fn(props)
}
export const space = (space: SpaceKeys) => (props: { theme: GlobalTheme }) => {
  const fn = get(space, props.theme.spaces)
  if (typeof fn === "string") return fn
  return fn(props)
}

export const getBg = (
  props: PropsWithChildren<{ bg?: ColorKeys; theme: GlobalTheme }>
) => get(props.bg || "white", props.theme.colors)
export const getColor = (
  props: PropsWithChildren<{ color?: ColorKeys; theme: GlobalTheme }>
) => get(props.color || "black", props.theme.colors)
export const getSpace = (
  props: PropsWithChildren<{ space?: SpaceKeys; theme: GlobalTheme }>
) => {
  const fn = get(props.space || "normal", props.theme.spaces)
  if (typeof fn === "string") return fn
  return fn(props)
}
export const getText = (
  props: PropsWithChildren<{
    type?: TextKeys
    mobileType?: TextKeys
    theme: GlobalTheme
  }>
) => {
  const fnM = props.mobileType
    ? get(props.mobileType, props.theme.texts)
    : get(props.type || "body", props.theme.texts)
  const fnT = get(props.type || "body", props.theme.texts)
  return `
  ${typeof fnM === "string" ? fnM : fnM(props)}
  ${up("tablet")(props)}{
    ${typeof fnT === "string" ? fnT : fnT(props)}
  }
`
}

export default theme
